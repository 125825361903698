import React from 'react'
import { Box, SimpleGrid, GridItem, Heading, Text, Button, Image } from '@chakra-ui/react'
import {Element } from 'react-scroll'
import Lottie from 'react-lottie'
import t1 from '../../assets/lottie/UpCake.json'

const top = {
    loop: true,
    autoplay: true,
    animationData: t1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
};

export default function FirstSec() {
  return (
    <>
        <Element name="Introdusction">
            <Box className='upcro_launch_main'>
                <Box className='width_full'>
                    <SimpleGrid columns={12} columnGap={3} rowGap={2} >
                        <GridItem colSpan={[12, 12, 12, 6]}>
                            <Box className='launch_text_box'>
                                <Box className='launch_text_box_inn' data-aos="fade-up" data-aos-delay="1000" data-aos-duration="800">
                                    <Heading as="h3">upCake</Heading>
                                    <Text>
                                    Paired against CAKE; upCAKE is the first ERC-31337 token paired against a native DEX governance token and our second UpToken launched on the Binance Smart Chain.
                                    </Text>
                                    <Box className='launch_btns'>
                                        <Button as="a" href='#' className='glow-on-hover'>BUY NOW</Button>
                                        <Button as="a" href='#' className='entr_a glow-on-hover'>ENTER APP</Button>
                                        <Button as="a" href='#' className='glow-on-hover'>CHART</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem colSpan={[12, 12, 12, 6]}>
                            <Box className='launch_text_box' data-aos="zoom-in" data-aos-delay="500">
                                
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                </Box>
                <Box className='upcro_first_img'>
                    <Lottie options={top} width={650} height={650}></Lottie>
                </Box>
               
                
            </Box>
        </Element>
    </>
  )
}
