import { Contract } from '@ethersproject/contracts'
import feeSplitterAbi from '../constants/abis/feeSplitter.json'
import { Web3Provider } from '@ethersproject/providers'
import { FEESPLITTER_ADDRESS } from '../constants'

export class PayFeesService {
    private contract: Contract;
    
    constructor(library: Web3Provider, account: string) {
        const signer = library.getSigner(account).connectUnchecked()
        this.contract = new Contract(FEESPLITTER_ADDRESS, feeSplitterAbi, signer);
    }

    public async payFees() {
        return await this.contract.payFees("0x982f535c1dA184876d6e264920EdcA36B78e9f4C");
    }

}

