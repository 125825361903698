import React from 'react';
import LayoutTwo from './LayoutTwo';
import Pools from "./Pools";

export default function PoolsPage() {
    return (
        <LayoutTwo>
            <Pools/>
        </LayoutTwo>
      )
}